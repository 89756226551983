import { RegisterUserActions } from 'store/actions/registeruser';
import { RegisterUserType } from '../action-types/index';
interface InitialState {
    saveUserData: any,
    saveUserHomeScreenData:any
}

const initialState = {
    saveUserData: {},
    saveUserHomeScreenData:{}
}

const registerUserReducer = (state: InitialState = initialState, action: RegisterUserActions): InitialState => {
    switch (action.type) {
        case RegisterUserType.REGISTER_USER_DATA:
            return { ...state, saveUserData: action.payload }

        case RegisterUserType.REGISTER_HOME_SCREEN_DATA:
            return {...state,saveUserHomeScreenData:action.payload}  
        default:
            return state;
    }
}
export default registerUserReducer