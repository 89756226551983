import { applyMiddleware, createStore } from 'redux';
import thunk from "redux-thunk";
import logger from "redux-logger";

import reducer from './reducer';

// ===========================|| REDUX - MAIN STORE ||=========================== //
const store = createStore(reducer,applyMiddleware(thunk,logger));


export { store };
