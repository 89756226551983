import { createContext, useContext, useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "./useLocalStorage";
import { useSessionStorage } from "./useSessionStorage";
import { loginApi, generateToken } from "services/MApiServices";
import { IdleTimerProvider } from "react-idle-timer";
import { SseFunc } from "services/SSE";
import { callResponse } from "services";
import { store } from "store";

const AuthContext = createContext({
  // ...initialState,
  login: (data) => Promise.resolve(),
  // verifyLogin: () => Promise.resolve(),
  logout: () => {},
  loginAuthUser: (data) => Promise.resolve(),
  // checkPermission: () => { },
});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  const [selectedProp, setSelectedProp] = useLocalStorage("selectedProp", null);
  const [notVerified, setNotVerified] = useLocalStorage("verified", false);
  const [modalSqft, setModalSqft] = useLocalStorage("modalSqft", false);
  const [remember, setRemember] = useState(false);
  const [loginUser, setLoginUser] = useSessionStorage(
    "loginUser",
    null,
    remember ? true : false
  );
  const navigate = useNavigate();

  const onIdle = async () => {
    // Close Modal Prompt
    await logout();
    // Do some idle action like log out your user
  };

  const onActive = (event) => {
    // Close Modal Prompt
    // Do some active action
  };

  const onAction = (event) => {
    // Do something when a user triggers a watched event
  };

  const login = async (loginUser) => {
    if (loginUser) {
      if (
        loginUser?.mobileNo === "03001234567" &&
        loginUser?.password === "1234"
      ) {
        // callResponse('Login Successfully! ', 'success')
        setUser({ user: 1 });
        navigate("/home");
      } else {
        callResponse("Enter correct credentials ", "error");
      }
    }
  };
  const loginAuthUser = async (loginUser, remember) => {
    console.log("erereere", remember);
    if (remember) {
      setRemember(true);
    }

    let obj = {
      username: loginUser.mobileNo,
      password: loginUser?.password,
    };
    const res = await generateToken(obj);
    debugger;
    if (res.status == "200") {
      const { accessToken, refreshToken } = res.data;
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("authToken", accessToken);

      if (accessToken && refreshToken) {
        const response = await loginApi(loginUser);

        if (
          response.data.message == "Login Successfully!" ||
          response.data.status == 200
        ) {
          // callResponse(response.data.message, 'success')

          const instance = new SseFunc();
          instance.setConnection();

          setLoginUser(response?.data?.data);
          navigate("/registeredHome");
        } else if (response?.data?.status == "4002") {
          setNotVerified(true);
          callResponse(response.data.message, "error");
          return false;
        } else {
          if (response?.data?.data?.mobileNo) {
            callResponse(response.data?.data?.mobileNo, "error");
          } else {
            callResponse(response.data.message, "error");
          }
        }
      } else {
        callResponse("Invalid credentials", "error");
      }
    } else {
      callResponse(res?.response.data.error, "error");
    }
  };
  // call this function to sign out logged in user
  const logout = (loginUser) => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("modalSqft");
    localStorage.removeItem("refreshToken");
    setLoginUser(null);
    // navigate("/home");
  };
  useEffect(() => {
    if (loginUser) {
      const instance = new SseFunc();
      instance.setConnection();
    }
  }, []);

  useEffect(() => {
    if (window.localStorage.authToken == undefined) {
      logout();
    }
  }, [window.localStorage.authToken]);

  const value = useMemo(
    () => ({
      user,
      selectedProp,
      setSelectedProp,
      notVerified,
      login,
      logout,
      loginAuthUser,
      loginUser,
      setLoginUser,
      modalSqft,
      setModalSqft,
    }),
    [user, loginUser]
  );

  return (
    <AuthContext.Provider value={value}>
      <IdleTimerProvider
        startManually={loginUser ?? true}
        timeout={300000}
        onIdle={onIdle}
        onActive={onActive}
        onAction={onAction}
      >
        {children}
      </IdleTimerProvider>
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
