import React from "react";
import "./ImageLoader.css"; // Import your CSS file for styling

const ImageLoader = () => {
  return (
    <div className="image-loader">
      <img
        style={{ width: "20%" }}
        className="center"
        src={`${process.env.PUBLIC_URL}/asset/wp-images/malkiyatLogo.png`}
        alt="Loading..."
      />
    </div>
  );
};

export default ImageLoader;
