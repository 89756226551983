import React, { useState } from "react";
import CookieConsent from "react-cookie-consent";

const CookieConsentBanner = () => {
  const [showBanner, setShowBanner] = useState(true);

  const handleAccept = () => {
    // window.gtag("event", "button_click", {
    //   event_category: "Engagement",
    //   event_label: "Cookie Accept Button",
    // });

    // Handle cookie consent acceptance
    setShowBanner(false);
  };

  const handleDecline = () => {
    // window.gtag("event", "button_click", {
    //   event_category: "Engagement",
    //   event_label: "Cookie Decline Button",
    // });

    // Handle cookie consent rejection
    setShowBanner(false);
    // You can perform actions like showing a message for declined consent
  };

  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      cookieName="cookieConsent"
      expires={365}
      enableDeclineButton
      onAccept={handleAccept}
      onDecline={handleDecline}
      style={{ background: "#333", textAlign: "center" }}
      buttonStyle={{
        color: "#fff",
        background: "#007BFF",
        marginRight: "10px",
      }}
      declineButtonStyle={{ color: "#333", background: "#eee" }}
    >
      This website uses cookies to ensure you get the best experience on our
      website.
    </CookieConsent>
  );
};

export default CookieConsentBanner;
