import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import ImageLoader from "./ImageLoader";

// Lazy-loaded components
const MainPage = lazy(() => import("views/mainPage/MainPage"));
const LoginAuth = lazy(() => import("views/loginPage/LoginAuth"));
const AboutUs = lazy(() => import("views/mainPage/AboutUs"));
const Careers = lazy(() => import("views/mainPage/Careers"));
const Privacy = lazy(() => import("views/mainPage/Privacy"));
const FAQ = lazy(() => import("views/mainPage/FAQ"));
const ContactUs = lazy(() => import("views/mainPage/ContactUs"));
const Disclaimer = lazy(() => import("views/mainPage/Disclaimer"));
const VeryMainPage = lazy(() => import("views/mainPage/VeryMainPage"));


export function MainRoutes() {
  return (
    <Routes>
      <Route
        path="/disclaimer"
        element={
          <Suspense fallback={<ImageLoader />}>
            <Disclaimer />
          </Suspense>
        }
      />
      <Route
        path="/careers"
        element={
          <Suspense fallback={<ImageLoader />}>
            <Careers />
          </Suspense>
        }
      />

      <Route
        path="/aboutUs"
        element={
          <Suspense fallback={<ImageLoader />}>
            <AboutUs />
          </Suspense>
        }
      />
      <Route
        path="/privacyPolicy"
        element={
          <Suspense fallback={<ImageLoader />}>
            <Privacy />
          </Suspense>
        }
      />
      <Route
        path="/faq"
        element={
          <Suspense fallback={<ImageLoader />}>
            <FAQ />
          </Suspense>
        }
      />
      <Route
        path="/contactUs"
        element={
          <Suspense fallback={<ImageLoader />}>
            <ContactUs />
          </Suspense>
        }
      />
      <Route
        path="/loginUser"
        element={
          <Suspense fallback={<ImageLoader />}>
            <LoginAuth />
          </Suspense>
        }
      />
      <Route
        path="/mainpage"
        element={
          <Suspense fallback={<ImageLoader />}>
            <MainPage />
          </Suspense>
        }
      />
        <Route
        path="/"
        element={
          <Suspense fallback={<ImageLoader />}>
            <VeryMainPage />
          </Suspense>
        }
      />
    </Routes>
  );
}
