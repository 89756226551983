// theme constant
import { valueWithCommas } from "utils/helperFunctions";
export const gridSpacing = 3;
export const drawerWidth = 260;
export const appDrawerWidth = 320;

export const modalStyle = {

    position: 'absolute',
    top: '50%',
    left: '50%',
    textAlign:'center',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border:0,
    borderRadius:"24px"
};
export const sessionStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    borderRadius:2,
    p: 2,
  };

  export const TransactionType = (item) => {

    switch (String(item.category).toLowerCase()) {
    case "cashin":
      return "Top up wallet";
      case "courier_charges":
      return "Courier Charges";
        case "stamp_paper_fee":
      return "Stamp Paper fee";
         case "commission":
      return "Malkiyat Fee";
          case "bought":
      return `Bought ${  valueWithCommas(item?.boughtSmallerUnit??0)?? ""} ${
        item?.smallerUnit+(Number(item?.boughtSmallerUnit??0)>1?"s":"")??""
      }`;
            case "withdraw":
              
      return  `Withdraw ${ valueWithCommas(item?.boughtSmallerUnit??0)?? ""} ${
        item?.smallerUnit ?? ""
      }`;
      case "cashout":
        return  'Withdraw'

      //        case "withdraw for ad":
      // return `Withdraw for Ad ${ item?.boughtSmallerUnit ?? ""} ${
      //   item?.smallerUnit ?? ""
      // }`;
               case "sold":
      return `Sold ${valueWithCommas(item?.boughtSmallerUnit??0) ?? ""} ${
        item?.smallerUnit+(Number(item?.boughtSmallerUnit??0)>1?"s":"") ?? ""
      }`;
                 default:
      return null;
  }
};
  
export const keyStatusesArray = ["1", "2", "3", "4"];
const RDropdownItems = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
  "32",
  "33",
  "34",
  "35",
  "36",
  "37",
  "38",
  "39",
  "40",
  "41",
  "42",
  "43",
  "44",
  "45",
  "46",
  "47",
  "48",
  "49",
  "50",
];
const URDropDownItems = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
export { RDropdownItems, URDropDownItems };
