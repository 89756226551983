import * as React from 'react';
import Box from '@mui/material/Box';
import { Checkbox, Grid, autocompleteClasses } from "@mui/material";
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Modal from '@mui/material/Modal';
import { useAuth } from 'hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { sessionStyle } from 'store/constant';


export default function Session() {
  const [open, setOpen] = React.useState(false);
  const modalInitial = useSelector((state: any) => state.modal);
  const { logout } = useAuth()
  const navigate = useNavigate()
  const handleClose = (event: any, reason: any) => {

    if (reason && reason == "backdropClick")
      return;


  }

  const handleSession = async () => {

    await logout()
    setOpen(false)

  }

  React.useEffect(() => {
    setOpen(modalInitial.open);
  }, [modalInitial.action, modalInitial.open]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={sessionStyle}>
          <CloseIcon className="cross" onClick={(e) => handleClose(e, "backdropClick")} />
          <img className='center width' src={`${process.env.PUBLIC_URL}/assets/images/market_modal.png`} />
          <Typography sx={{ mt: 1, textAlign: "center" }} id="modal-modal-title" variant="subtitle1" component="h2">
            {modalInitial.message}
          </Typography>
          <Grid className="" xs={7} item>
            <Button onClick={() => handleSession()} style={{ display: "block", marginLeft: "auto", marginRight: "auto" }} variant='contained'>  Ok </Button>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}