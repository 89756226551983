import axios from "axios";
import { Dispatch } from "react";
import { SellSqftsI, CreateBidI } from "./Models";
import _ from 'lodash';
import { store } from "store";
import { MODAL_OPEN } from "store/actions";
import { callResponse } from 'services';
import { RegisterUserType } from "store/action-types";
import { UnRegisterUserType } from "store/action-types";
import { property } from 'lodash';


const instanceToken = axios.create({
  baseURL: "https://dev.ttnlabs.com:8080",
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  timeout: 3000000,
});

const instance = axios.create({
  baseURL: "https://dev.ttnlabs.com:8080",
  headers: {
    "Content-Type": "application/json",
    // Authorization: `Bearer ${localStorage.getItem('authToken')}`
    "requestFrom": "web"
  },
  timeout: 3000000,
});
const instanceUnregister = axios.create({
  baseURL: "https://dev.ttnlabs.com:8080",
  headers: {
    "Content-Type": "application/json",

  },
  timeout: 3000000,
});

const source = axios.CancelToken.source();

const setRegisterAuth = (authToken: any) => {
  instance.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${authToken}`;
};

// instance.interceptors.request.use(
//   (config: any) => {
//     const token = window.localStorage.authToken;
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`
//     }
//     return config
//   },
//   (error) => {
//     console.log("error", error);
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (response) => {
//     console.log(response, "...");

//     return response;
//   },
//   (error) => {
//     const status = error?.response?.status || 0;
//     if (status == 401) {
//       console.log("|||||||||||||||||||||||", status);
//       refreshToken();
//       return Promise.reject(error);
//     } else {
//       return Promise.reject(error);
//     }
//   }
// );

const refreshToken = async () => {
  const obj = {
    refreshToken: localStorage.getItem('refreshToken'),
  };

  try {
    const res = await instance.post("oauth/v1/token/refresh", obj, { cancelToken: source.token });
    if (res.status == 200 && !_.isEmpty(res.data)) {
      // localStorage.getItem('refreshToken',res.data.refreshT),
      localStorage.setItem('authToken', res.data.accessToken)
      setRegisterAuth(res.data.accessToken);
      // store.dispatch(saveToken(res.data) as any);
      return true;
    }
    else {
      return false;
    }
  } catch (error) {
    console.log(
      "🚀 ~ file: MRegisterUserApiService.ts ~ line 123 ~ MRegisterUserService ~ refreshToken= ~ error",
      error

    );

    localStorage.removeItem('authToken')
    localStorage.removeItem('refreshToken')

    // callResponse('session Expire', 'error')

    store.dispatch({
      type: MODAL_OPEN,
      open: true,
      message: "Your session has been expired.Please login again.",

    })
    return source.cancel()
  }
};


const forgotPasswordApi = async (phoneNo: string | undefined) => {
  const phone = { mobileNo: phoneNo };
  try {
    return await instance.post("User/forgetPassword", phone);
  } catch (error) {
    callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}

const createPass = async (obj: any) => {

  try {
    return await instance.post("User/createPass", obj);
  } catch (error) {
    callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}

const transactionApi = async (obj: any) => {

  try {
    return await instance.post("transaction/", obj);
  } catch (error) {
    callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}



const cashInToMalkiyat = async () => {
  try {
    return await instance.get("transaction/getMalkiyatAccDetails");
  } catch (error) {
    console.log(error);
    return error;
  }
};




const buySellAdApi = async (userId: number) => {

  try {
    return await instance.get(`property/propDetailsForAds/${userId}`);
  } catch (error) {

    // callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}



const iownProofApi = async (data: any) => {

  try {
    return await instance.post("transaction/getPopOfOwnedUnits",
      data);
  } catch (error) {

    // callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}








const upladRecieptApi = async (obj: any) => {
  let image: any = localStorage.getItem('uploadReceipt')
  image = image.split(",").pop();
  obj.receiptImg = image
  try {
    return await instance.post("transaction/uploadReceipt", obj);
  } catch (error) {

    // callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}



const calculateFeeApi = async (obj: any) => {

  try {
    return await instance.post("property/calculateFee", obj);
  } catch (error) {

    // callResponse("Please try again later!", "error")
    console.log(error);
    return error;
  }
}


const sendCashOutOtp = async (id: number) => {
  try {
    return await instance.get(`User/sendOtp/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const getPaymentMethods = async () => {
  try {
    return await instance.get("transaction/getPaymentMethods");
  } catch (error) {
    console.log(error);
    return error;
  }
};

const resetPassword = async (passwordObj: any) => {
  try {
    return await instance.post("User/changePassword", passwordObj);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const resetPasswordStart = async (passwordObj: any) => {
  try {
    return await instance.post("User/resetPassword", passwordObj);
  } catch (error) {
    console.log(error);
    return error;
  }
};




const signUpApi = async (values: any) => {
  try {
    return await instance.post("User/signUp", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const resendOtpApi = async (phone: any) => {
  try {
    return await instance.post("User/resendOtp", {
      mobileNo: phone,
    });
  } catch (error) {
    console.log(error);
    return error;
  }
};

const userProfileApi = async (values: any, loginUser: any) => {

  try {
    const res = await instance.post("User/userProfile", values);

    if (res.status == 200 && !_.isEmpty(res.data)) {
      // dispatch({
      //   type: RegisterUserType.SAVE_USER_PROFILE_DATA,
      //   payload: res?.data?.data ?? {},
      // });
      return res;
    } else {
      console.warn("something went wrong!", res.data);
      callResponse("Please try again later!", "error")

      return res;
    }

  } catch (error) {
    console.log(error);
    return error;
  }
};



const verifyCashOutOtpApi = async (otpObj: any) => {
  try {
    return await instance.post("User/verifyTransOtp", otpObj);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const verifyOtpApi = async (otpObj: any) => {
  try {
    return await instance.post("User/verifyOtp", otpObj);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const proofOfPurchaseData = async () => {
  try {
    return await instance.get("transaction/pop");
  } catch (error) {
    console.log(error);
    return error;
  }
};
const makePayment = async (values: any) => {
  try {
    return await instance.post("transaction/verifyTransaction", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const initiateTransaction = async (values: any) => {
  try {
    return await instance.post("transaction/initiateTransaction", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const buyBackFromMalkiyat = async (values: any) => {
  try {
    return await instance.post("transaction/buyBack", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const makePaymentWithPayfast = async (values: any) => {
  try {
    return await instance.post("transaction/payWithPayFast", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const registeredHomeScreen = (userId: any) => async (dispatch: Dispatch<any>) => {
  try {

    const data = await instance.get(`property/registerHomeScreen/${userId}`);
    dispatch({ type: RegisterUserType.REGISTER_HOME_SCREEN_DATA, payload: data.data?.data })
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};

const ownedProperty = async (userId: any, propertyId: number) => {
  try {
    return await instance.get(`property/ownedUnits/${userId}/${propertyId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const support = async () => {
  try {
    return await instance.get("utility/support");
  } catch (error) {
    console.log(error);
    return error;
  }
};

const privacyPolicy = async () => {
  try {
    return await instance.get("utility/privacyPolicy");
  } catch (error) {
    console.log(error);
    return error;
  }
};

const transactions = async (userId: any) => {
  try {
    return await instance.get(`transaction/userTransactions/${userId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const proofUpload = async (data: any) => {
  try {
    return await instance.post("User/uploadImg", data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const loginApi = async (data: any) => {
  data.requestFrom = "web"
  try {
    return await instance.post("User/login", data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const generateToken = async (data: any) => {
  try {
    return await instance.post("/oauth/v1/token/generate", data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const refreshTokenApi = async (data: any) => {
  try {
    return await instanceToken.post("/oauth/v1/token/refresh", data);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const verifyingEmail = async (email: number) => {
  try {
    return await instance.get(`User/checkEmail/${email}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};



const getBalance = async (userId: number) => {
  try {
    return await instance.get(`balance/wallet/${userId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};





const priceGuideApi = async (userId: number, propertyId: number) => {
  try {
    return await instance.get(`property/priceguide/${userId}/${propertyId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const highestOfferBid = async (userId: number, propertyId: number, forSaleUnits: number) => {
  try {
    return await instance.get(`property/highestbid?userId=${userId}&propertyId=${propertyId}&forSaleUnits=${forSaleUnits}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const lowestBidOffer = async (data: any) => {
  try {
    return await instance.post(`property/lowestbid`, data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const sellSqftsApi = async (obj: SellSqftsI) => {
  try {
    return await instance.post("transaction/sellMySmallerUnit", obj);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const createBidByUser = async (data: CreateBidI) => {
  try {
    return await instance.post("property/addBidOffer", data);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const myAdsApi = async (userId: number) => {
  try {
    return await instance.get(`property/myAdsScreen/${userId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const getAllVouchers = async (userId: number) => {
  try {
    const res = await instance.get(`User/vouchers/${userId}`);
    return res;
  } catch (error) {
    console.log("🚀 vouchers", error);
  }
};
const cancelAdApi = async (propertyId: number) => {
  try {
    return await instance.get(`property/bids/inactive/${propertyId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const userProperties = async (userId: any) => {
  try {
    return await instance.get(`property/userProperties/${userId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
}


const initiatePayment = async (values: any) => {
  try {
    return await instance.post("transaction/initiateTransaction",
      values);
  } catch (error) {
    console.log(error);
    return error;
  }
}



const recentGift = async (id: number) => {
  try {
    return await instance.get(`property/recentGifts/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const receivedGift = async (id: number) => {
  try {
    return await instance.get(`property/receivedGifts/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const getCashoutTransactionsInProcess = async (id: number) => {
  try {
    return await instance.get(`transaction/requests/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};





const SqftDetailsPropertyList = async (userid: number, propertyId: number) => {
  try {
    return await instance.get(`property/sqftsDetails/${userid}/${propertyId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const getGiftType = async () => {
  try {
    return await instance.get("property/giftTypes");
  } catch (error) {
    console.log(error);
    return error;
  }
};

const fetchUserTitle = async (mobileNo: string) => {
  try {
    return await instance.get(`User/fetchTitle/${mobileNo}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const buyBackApi = async (obj: any) => {
  try {
    return await instance.post(`transaction/buyBack`, obj);
  } catch (error) {
    console.log(error);
    return error;
  }
};



const buyBackDetails = async (id: number) => {
  try {
    return await instance.get(`property/buyBackDetails/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const buyBackPropertyList = async (userId: number, propertyId: number) => {
  try {
    return await instance.get(`property/buyBackDetails/${userId}/${propertyId}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};



const giftSqfts = async (values: any) => {
  try {
    return await instance.post("property/giftSqfts", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const buyBackUnitst = async (userid: number,
  propertyId: number,
  date: any) => {
  const obj = { userId: userid, propertyId: propertyId, date: date };
  try {
    return await instance.post("property/buyBackUnits", obj);
  } catch (error) {
    console.log(error);
    return error;
  }
};




const getBankList = async () => {
  try {
    return await instance.get("transaction/bankList");
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getTransactionDetailByReferenceId = async (referenceId: string,
  type: any) => {

  try {
    return await instance.get(`transaction/userTransactions/ ${referenceId}/${type}`);
  } catch (error) {
    console.log(error);
    return error;
  }


}

// const registeredHomeScreen = (userId: any) => async (dispatch: Dispatch<any>) => {
//   try {

//     const data = await instance.get(`property/registerHomeScreen/${userId}`);
//     dispatch({ type: RegisterUserType.REGISTER_HOME_SCREEN_DATA, payload: data })
//     return data;
//   } catch (error) {
//     console.log(error);
//     return error;
//   }
// };



const unregisterHomeScreen = () => async (dispatch: Dispatch<any>) => {
  try {
    const data = await instanceUnregister.get("UnRegister/homeScreen");
    dispatch({ type: UnRegisterUserType.UNREGISTER_HOME_SCREEN_DATA, payload: data?.data?.data?.List })
    return data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
const unregisterPropertyDetails = async (id: number) => {
  try {
    return await instanceUnregister.get(`UnRegister/propAllDetails/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};





const unregisterIncreasePerYear = async (id: number) => {
  try {
    return await instanceUnregister.get(`UnRegister/increasePerYear/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const titlePossession = async (values: any) => {
  try {
    return await instance.post("property/enablePossession", values);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const SqftByProperty = async (id: number) => {
  try {
    return await instance.get(`property/sqftsByProperty/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const userCashOutApi = async (obj: any) => {
  try {
    return await instance.post("transaction/cashOut", obj);
  } catch (error) {
    console.log(error);
    return error;
  }
};


const propertyLandmarks = async (id: number) => {
  try {
    return await instanceUnregister.get(`property/landmarks/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
const propertySoldHistory = async (id: number) => {
  try {
    return await instanceUnregister.get(`property/soldHistory/${id}`);
  } catch (error) {
    console.log(error);
    return error;
  }
};
export {
  ownedProperty,
  getGiftType,
  SqftDetailsPropertyList,
  recentGift,
  receivedGift,
  fetchUserTitle,
  giftSqfts,
  SqftByProperty,
  getCashoutTransactionsInProcess,
  getBankList,
  resendOtpApi,
  getAllVouchers,
  resetPasswordStart,
  forgotPasswordApi,
  initiatePayment,
  getBalance,
  privacyPolicy,
  support,
  resetPassword,
  sendCashOutOtp,
  buyBackFromMalkiyat,
  initiateTransaction,
  setRegisterAuth,
  refreshTokenApi,
  generateToken,
  cancelAdApi,
  myAdsApi,
  createBidByUser,
  sellSqftsApi,
  highestOfferBid,
  priceGuideApi,
  loginApi,
  proofUpload,
  transactions,
  buyBackDetails,
  registeredHomeScreen,
  userProperties,
  makePaymentWithPayfast,
  makePayment,
  proofOfPurchaseData,
  verifyOtpApi,
  createPass,
  buyBackUnitst,
  transactionApi,
  buySellAdApi,
  signUpApi,
  titlePossession,
  getPaymentMethods,
  verifyCashOutOtpApi,
  buyBackApi,
  cashInToMalkiyat,
  calculateFeeApi,
  iownProofApi,
  unregisterIncreasePerYear,
  buyBackPropertyList,
  upladRecieptApi,
  propertyLandmarks,
  verifyingEmail,
  userCashOutApi,
  getTransactionDetailByReferenceId,
  propertySoldHistory,
  unregisterPropertyDetails,
  userProfileApi,
  unregisterHomeScreen, lowestBidOffer
};



