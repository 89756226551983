import { EventMessageI ,HighestOfferDataI} from '../services/Models';
import _ from 'lodash'
import moment from 'moment';
const LAKH = 100000;
const CRORE = 10000000;
const MAX_COINS = 12;

export const valueWithCommas = (num: number) => {
    let nf = new Intl.NumberFormat("en-US");
    return nf.format(num);

};

export function diffYMDHMS(date1:any, date2:any, timeSpan: string): string {
  console.log(timeSpan, "timeSpan");

  // let years = moment(date2, "YYYY-MM-DD HH:mm:ss").diff(date1, "years");
  // let months = moment(date2, "YYYY-MM-DD HH:mm:ss").diff(date1, "months");
  let days = moment(date2, "YYYY-MM-DD HH:mm:ss").diff(date1, "days");
  const totalDays = Number(timeSpan) * 365;
  const remaningDays = Number(totalDays) - Number(days);

  var start = moment();
  var end = moment().add(remaningDays, "days");

  const duration = moment.duration(end.diff(start));

  // if (years > 0) {
  //   const remainingyears =
  //     years + "." + String(Number(months) > 0 ? months : "");
  //   if (Number(remainingyears) > 1) {
  //     return remainingyears + " years";
  //   } else {
  //     return remainingyears + " year";
  //   }
  // }
  // if (months > 0) {
  //   return months + " months";
  // }
  // if (days > 0) return days + " days";
  // else return "";
  return (
    String(
      (duration.years() > 0 ? duration.years() : "") + "." + duration.months()
    ) + " years"
  );
}



export function propertyAdressCheck(
  propertyName: string,
  propertyAdress: string
): string {
  if (propertyName?.length > 15 && propertyAdress?.length > 35) {
    return propertyAdress.slice(0, 35) + "...";
  }
  if (propertyName?.length > 15 && propertyAdress?.length <= 35) {
    return propertyAdress.slice(0, 25) + "...";
  }
  if (propertyName?.length < 15 && propertyAdress?.length > 40) {
    return propertyAdress?.slice(0, 40) + "...";
  }
  if (propertyName?.length < 15 && propertyAdress?.length < 35) {
    return propertyAdress?.slice(0, 38) + "...";
  } else {
    return propertyAdress;
  }
}


export const TransactionType = (item: any) => {

  switch (String(item.category).toLowerCase()) {
  case "cashin":
    return "Top up wallet";
    case "courier_charges":
    return "Courier Charges";
      case "stamp_paper_fee":
    return "Stamp Paper fee";
       case "commission":
    return "Malkiyat Fee";
        case "bought":
    return `Bought ${  valueWithCommas(item?.boughtSmallerUnit??0)?? ""} ${
      item?.smallerUnit+(Number(item?.boughtSmallerUnit??0)>1?"s":"")??""
    }`;
          case "withdraw":
            
    return  `Withdraw ${ valueWithCommas(item?.boughtSmallerUnit??0)?? ""} ${
      item?.smallerUnit ?? ""
    }`;
    case "cashout":
      return  'Withdraw'

    //        case "withdraw for ad":
    // return `Withdraw for Ad ${ item?.boughtSmallerUnit ?? ""} ${
    //   item?.smallerUnit ?? ""
    // }`;
             case "sold":
    return `Sold ${valueWithCommas(item?.boughtSmallerUnit??0) ?? ""} ${
      item?.smallerUnit+(Number(item?.boughtSmallerUnit??0)>1?"s":"") ?? ""
    }`;
               default:
    return null;
}
};


export const circleProgressHours = (
  createdDate: Date,
  expiryDate: Date
): number => {
  const totalHours = moment
    .duration(moment(expiryDate).diff(moment(createdDate)))
    .asHours();
  const remainingHours = moment
    .duration(moment(expiryDate).diff(moment()))
    .asHours();

  const value = (Number(remainingHours) / Number(totalHours)) * 100;

  return value > 0 ? value : 1;
};

export const calculateTimeStampWallet = (
  param: Date | string | any,
  todayDate: any = moment()
) => {
  if (
    !_.isEmpty(param) &&
    _.isUndefined(param) &&
    param!.toString()?.length <= 12
  ) {
    return "";
  }

  const giveDate = moment(param);

  // param = moment(param).local();

  // var diffTime = 0;
  // if (param != "null" && param.includes("T")) {
  //   diffTime = Math.abs(+new Date() - +new Date(param));
  // } else {
  //   diffTime = Math.abs(+new Date() - +new Date(param));
  // }

  let days = Math.abs(todayDate.diff(giveDate, "days"));
  let hours = Math.abs(todayDate.diff(giveDate, "hours"));
  let minutes = Math.abs(todayDate.diff(giveDate, "minutes"));
  let secs = Math.abs(todayDate.diff(giveDate, "seconds"));

  // let days = diffTime / (24 * 60 * 60 * 1000);
  // let hours = (days % 1) * 24;
  // let minutes = (hours % 1) * 60;
  // let secs = (minutes % 1) * 60;

  [days, hours, minutes, secs] = [days, hours, minutes, secs];

  if (days >= 365) {
    const year = Math.floor(days / 365);
    return `${year} year${year > 1 ? "s" : ""}`;
  }
  if (days >= 60) {
    return `${Math.floor(days / 28)} ${days > 30 ? "months" : "month"}`;
  }
  if (days > 0) {
    return `${days} ${days > 1 ? "days" : "day"}`;
  } else if (hours > 0) {
    return `${hours} ${hours > 1 ? "hours" : "hour"}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
  } else {
    return `${secs} secs`;
  }
};

export const calculateTimeStamp = (
  param: Date | string | any,
  todayDate: any = moment()
) => {
  if (
    !_.isEmpty(param) &&
    _.isUndefined(param) &&
    param!.toString()?.length <= 12
  ) {
    return "";
  }

  const giveDate = moment(param);

  // param = moment(param).local();

  // var diffTime = 0;
  // if (param != "null" && param.includes("T")) {
  //   diffTime = Math.abs(+new Date() - +new Date(param));
  // } else {
  //   diffTime = Math.abs(+new Date() - +new Date(param));
  // }

  let days = Math.abs(todayDate.diff(giveDate, "days"));
  let hours = Math.abs(todayDate.diff(giveDate, "hours"));
  let minutes = Math.abs(todayDate.diff(giveDate, "minutes"));
  let secs = Math.abs(todayDate.diff(giveDate, "seconds"));

  // let days = diffTime / (24 * 60 * 60 * 1000);
  // let hours = (days % 1) * 24;
  // let minutes = (hours % 1) * 60;
  // let secs = (minutes % 1) * 60;

  [days, hours, minutes, secs] = [days, hours, minutes, secs];

  if (days >= 365) {
    const year = Math.floor(days / 365);
    return `${year} year${year > 1 ? "s" : ""}`;
  }
  if (days >= 60) {
    return `${Math.floor(days / 28)} ${days > 30 ? "months" : "month"}`;
  }
  if (days > 0) {
    return `${days} ${days > 1 ? "days" : "day"}`;
  } else if (hours > 0) {
    return `${hours} ${hours > 1 ? "hours" : "hour"}`;
  } else if (minutes > 0) {
    return `${minutes} ${minutes > 1 ? "minutes" : "minute"}`;
  } else {
    return `${secs} secs`;
  }
};
export const valueConverstion = (value: number) => {
    let val: any = Math.abs(value);
    if (val < LAKH) {
        return valueWithCommas(value);
    }

    if (val >= CRORE) {
        val = (val / CRORE).toFixed(2) + " Crore";
    } else if (val >= LAKH) {
        val = (val / LAKH).toFixed(2) + " lac";
    }
    return val;
};

export const coinStackforPercentage = (val: number, interval: number) => {
    let arr: number[] = [];
    let num = 0;

    if (Math.round(val / interval) === 0) {
        num = 1;
    } else {
        num = Math.round(val / interval);
    }
    for (let i = 1; i <= num; i++) {
        arr.push(i);
    }
    return arr;
};
export const coinStackforYear = (currentYear: number, maxValueofYear: number) => {
    let arr: number[] = [];

    let num = 0;
    let convertedVal = (currentYear / maxValueofYear) * MAX_COINS;
    if (Math.round(convertedVal) == 0) {
        num = 1;
    } else {
        num = Math.round(convertedVal);
    }
    for (let i = 1; i <= num; i++) {
        arr.push(i);
    }
    return arr;
};
export const calculateProfilt = (
    message: EventMessageI | null,
    bidData: HighestOfferDataI,
    comparison: boolean
  ): {
    profit: number | null;
    totalPurchasePrice:any;
  } => {
    const data = bidData;
  
    if (_.size(data?.userProperties) > 0) {
      var no_of_units: number | null = 0;
  
      if (_.isEmpty(message)) {
        no_of_units =
          data?.totalForSaleSmallerUnits! > data?.ownedSmallerUnits!
            ? data.ownedSmallerUnits
            : data.totalForSaleSmallerUnits!;
      } else if (comparison) {
        no_of_units =
          message!?.totalForSaleSmallerUnits! > data?.ownedSmallerUnits!
            ? data?.ownedSmallerUnits
            : message?.totalForSaleSmallerUnits!;
      } else {
        no_of_units = data.ownedSmallerUnits;
      }
  
      var totalPurchasePrice: number = 0;
  
      for (let i = 0; i < Number(no_of_units!); i++) {
        totalPurchasePrice += Number(data?.userProperties[i].purchasePrice);
      }
  
      const obj = {
        profit: no_of_units! * message?.highestOfferAmount! - totalPurchasePrice,
        totalPurchasePrice,
      };
      return obj;
    }
    // ! IF THERE IS NO PROPERTY EXIST AGAINST THIS USER
  
    return { profit: 0, totalPurchasePrice: 0 };
  };

  export const TransactionTypeOwn = (item: any) => {

    switch (String(item.category).toLowerCase()) {
    case "cashin":
      return "Top up wallet";
      case "courier_charges":
      return "Courier Charges";
        case "stamp_paper_fee":
      return "Stamp Paper fee";
         case "commission":
      return "Malkiyat Fee";
          case "bought":
      return `Bought ${  valueWithCommas(item?.boughtSmallerUnit??0)?? ""} ${
        item?.smallerUnit+(Number(item?.boughtSmallerUnit??0)>1?"s":"")??""
      }`;
            case "withdraw":
              
      return  `Withdraw ${ valueWithCommas(item?.boughtSmallerUnit??0)?? ""} ${
        item?.smallerUnit ?? ""
      }`;
      case "cashout":
        return  'Withdraw'

      //        case "withdraw for ad":
      // return `Withdraw for Ad ${ item?.boughtSmallerUnit ?? ""} ${
      //   item?.smallerUnit ?? ""
      // }`;
               case "sold":
      return `Sold ${valueWithCommas(item?.boughtSmallerUnit??0) ?? ""} ${
        item?.smallerUnit+(Number(item?.boughtSmallerUnit??0)>1?"s":"") ?? ""
      }`;
                 default:
      return null;
  }
};
  
  export const PercentageData: {
    id: Required<number>;
    percentage: number | undefined;
  }[] = [
    {
      id: 1,
      percentage: 25,
    },
    {
      id: 2,
      percentage: 50,
    },
    {
      id: 3,
      percentage: 75,
    },
    {
      id: 4,
      percentage: 100,
    },
  ];