import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill';
import EventEmitter from "./EventEmitter";
import { Events } from "./Events";
function SseFunc() {
        const es = new EventSourcePolyfill("https:dev.ttnlabs.com:8080/sse/subscribe"
        ,
        {
                headers:{
                        "Authorization":`Bearer ${localStorage.getItem('authToken')}`,
                        "Content-Type":"text/event-stream",
                        'Cache-Control':'no-cache',
                        'Connection':'keep-alive'
                },
                heartbeatTimeout:12000000

        
        
        }
        );
        console.log(es,'...');
        
        SseFunc.prototype.setConnection = function () {
                es.addEventListener("open", (connection) =>
                 console.log("SSE CONNECTION", connection)
                //  clearTimeout(2)
                 );
                es.addEventListener("error", (error) => console.log("SSE ERROR", error));
                es.addEventListener("message", (message) => handleMessage(message));
        }
}
export { SseFunc };


function handleMessage(message: any) {
        console.log(message, "new message is hello");
        EventEmitter.emit(Events.newMessage as any, message);

}

