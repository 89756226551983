// action - state management
import * as actionTypes from './actions';

const initialState = {
    action: false,
    open: false,
    message: 'Something went wrong',

};

// ===========================|| SNACKBAR REDUCER ||=========================== //

const modalReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MODAL_OPEN:
            return {
                ...state,
                action: !state.action,
                open: action.open ? action.open : initialState.open,
                message: action.message ? action.message : initialState.message,
           
            };
        default:
            return state;
    }
};

export default modalReducer;
