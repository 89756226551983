// action - state management
import { ACCOUNT_INITIALIZE, LOGIN, LOGOUT, REMEMBER } from './actions';

// ===========================|| ACCOUNT REDUCER ||=========================== //

const accountReducer = (state, action) => {
    // const navigate = useNavigate()
    // const { setLoginUser } = useAuth()
    switch (action.type) {
        case LOGOUT: {
            return {
                ...state,
                isLoggedIn: false,
                user: null,
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
