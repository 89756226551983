import { combineReducers } from 'redux';
import snackbarReducer from './snackbarReducer';
import accountReducer from './accountReducer';
import modalReducer from './modalReducer';
import registerReducer from "./reducer/registeruser"
import unregisterUserReducer from "./reducer/unregisteruser"

// import cartReducer from './cartReducer';

// ===========================|| COMBINE REDUCER ||=========================== //

const reducer = combineReducers({
    // customization: customizationReducer,
    snackbar: snackbarReducer,
    accountReducer,
    modal:modalReducer,
    registerReducer,
    unregisterUserReducer,

    // cart: persistReducer(
    //     {
    //         key: 'cart',
    //         storage,
    //         keyPrefix: 'berry-'
    //     },
    //     cartReducer
    // )
});

export default reducer;
