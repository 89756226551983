export enum RegisterUserType{
    REGISTER_USER_DATA="REGISTER_USER_DATA",
    REGISTER_HOME_SCREEN_DATA="REGISTER_HOME_SCREEN_DATA",
}
export enum UnRegisterUserType{

    UNREGISTER_USER_DATA="UNREGISTER_USER_DATA",
    UNREGISTER_HOME_SCREEN_DATA="UNREGISTER_HOME_SCREEN_DATA",

}
