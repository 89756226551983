import { UnRegisterUserActions } from 'store/actions/unregisteruser';
import { UnRegisterUserType } from '../action-types/index';
interface InitialState {

    saveUnregisterUserData: any,
    saveUnregisterterHomeScreenData:any
}

const initialState = {

    saveUnregisterUserData: {},
    saveUnregisterterHomeScreenData:{}
}


const unregisterUserReducer = (state: InitialState = initialState, action: UnRegisterUserActions): InitialState => {
    switch (action.type) {
        // case UnRegisterUserType.UNREGISTER_USER_DATA:
        //     return { ...state, saveUnregisterUserData: action.payload }

        case UnRegisterUserType.UNREGISTER_HOME_SCREEN_DATA:
            console.log(action.payload,'.........................');
            
            return {...state,saveUnregisterterHomeScreenData:action.payload}  
        default:
            return state;
    }
}
export default unregisterUserReducer
