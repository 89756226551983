import "./App.css";
import { useEffect } from "react";
import ReactGA from "react-ga";
import Snackbar from "ui-component/extended/Snackbar";
import Session from "ui-component/extended/Session";
import { MainRoutes } from "routes";
import CookieConsentBanner from "components/CookieConsentBanner";
import { AuthProvider } from "hooks/useAuth";

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <AuthProvider>
      <CookieConsentBanner />
      <Session />
      <Snackbar />
      <MainRoutes />
    </AuthProvider>
  );
}

export default App;
