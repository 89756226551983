import { store } from "store"
import { SNACKBAR_OPEN } from "store/actions";
import { useNavigate, Navigate } from 'react-router-dom';

const callResponse = (message: string, error: string) => {
   store.dispatch({
      type: SNACKBAR_OPEN,
      open: true,
      message: message,
      variant: 'alert',
      alertSeverity: error
    })
  }



  export {callResponse};