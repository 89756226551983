import EventEmitter from "eventemitter3";

type EventTypes="newMessage";
var eventEmitter= new EventEmitter();
const Emitter ={
    on:(event:any,fn:(...args:EventTypes[])=>void)=>eventEmitter.on(event,fn),
    once:(event:any,fn:(...args:EventTypes[])=>void)=>eventEmitter.once(event,fn),
    off:(event:any,fn:(...args:EventTypes[])=>void)=>eventEmitter.off(event,fn),
    emit:(event:any,fn:(...args:EventTypes[])=>void)=>eventEmitter.emit(event,fn)
}
Object.freeze(Emitter);
export default eventEmitter;